import { useProductStore } from "@/store/productStore"
import useSessionStorageCleanup from "@/utils/useSessionStorageCleanup"

const WHITELIST_PATH = ["products/"]

const useDeleteProductStorage = () => {
  const { clearStoredProduct, storedProduct } = useProductStore()

  const cleanupProductStorage = () => {
    if (!!Object.keys(storedProduct).length) {
      clearStoredProduct()
    }
  }

  useSessionStorageCleanup(cleanupProductStorage, WHITELIST_PATH)
}

export default useDeleteProductStorage
