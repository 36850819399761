"use client"

import { useChannelListContext } from "@sendbird/uikit-react/ChannelList/context"
import { useChatNotification } from "chat"
import Image, { type StaticImageData } from "next/image"
import Link from "next/link"
import { useParams, usePathname, useSearchParams } from "next/navigation"
import { type Session } from "next-auth"
import { NotificationModal, useNotificationByFeed } from "notifications"
import { useState, type MouseEvent } from "react"
import { Bell, ChevronDown, LogOut, Mail, Settings } from "react-feather"
import LogoManajemenPenyedia from "shared-assets/icons/logo-manajemen-penyedia.svg"
import { Button, Dropdown, DropdownItem, Avatar } from "shared-ui"

import { useGetUnreadChatCount } from "./components/hooks/useGetUnreadChatCount"

import { signIn, signOut } from "@/authentication/authService"
import ROUTE from "@/config/route"
import { getAccountPpnUrl } from "@/utils/common"
import useCheckPathname from "@/utils/useCheckPathname"

interface IHeaderProps {
  session?: Session | null
}

const Header = ({ session }: IHeaderProps) => {
  const callbackUrl = useSearchParams()?.get("callbackUrl") || ""
  const pathname = usePathname()
  const params = useParams() || {}

  const hiddenHeaderAddressPath = ["/invoice", "/auth/signin", "/auth/signout"]

  const [showNotificationModal, setShowNotificationModal] =
    useState<boolean>(false)
  const isBankOtp = useCheckPathname(["bank-otp"])
  const isEnterpriseRedirectPage = useCheckPathname([
    "/auth/enterprise-redirect",
  ])
  const isHiddenNavbar =
    hiddenHeaderAddressPath.some((item) => {
      if (pathname) return pathname === item
    }) || Object.keys(params).includes("fieldname")

  const { allChannels } = useChannelListContext()
  const isAuthenticated = Boolean(session?.user?.id)
  const { unseenCount, isNotificationEmpty } = useNotificationByFeed(undefined)
  const chatNotification = useChatNotification(allChannels)
  const { data } = useGetUnreadChatCount()

  const handleSignin = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    signIn("auth0", { callbackUrl })
  }

  const handleSignout = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    signOut()
  }

  const handleModalNotification = (open: boolean) => {
    setShowNotificationModal(open)
  }

  return (
    <div
      style={{
        boxShadow: "inset 0px -1px 0px #EBEBED",
        display: isHiddenNavbar ? "none" : "block",
      }}
      id="header-navigation"
    >
      <div
        className="mx-auto flex w-full items-center px-10"
        style={{
          height: "74px",
          maxWidth: "1920px",
        }}
      >
        <Image
          data-test="header-logo"
          src={LogoManajemenPenyedia as StaticImageData}
          alt="Katalog Elektronik logo"
          width={150}
          height={20}
        />

        {!isBankOtp && (
          <section className="ml-auto flex items-center gap-2">
            {isAuthenticated && !isEnterpriseRedirectPage && (
              <>
                <div className="flex items-center gap-4">
                  <Dropdown
                    onOpenChange={() => setShowNotificationModal(false)}
                    open={showNotificationModal}
                    trigger={
                      <div
                        className="relative cursor-pointer"
                        onClick={() =>
                          handleModalNotification(!showNotificationModal)
                        }
                      >
                        <Bell size={24} color="#344054" />
                        {!isNotificationEmpty && !!unseenCount && (
                          <div className="absolute -right-1 -top-2 h-[21px] min-w-[21px] rounded-full border-2 border-primary25 bg-primary400 px-1 text-center text-xs text-primary25">
                            {unseenCount >= 10 ? "9+" : unseenCount}
                          </div>
                        )}
                      </div>
                    }
                  >
                    <NotificationModal
                      isOpen={showNotificationModal}
                      onClose={() => handleModalNotification(false)}
                    />
                  </Dropdown>
                  <Link href={ROUTE.chat()} className="relative cursor-pointer">
                    <Mail size={24} color="#344054" />
                    {(pathname !== "/chat"
                      ? !!data?.count
                      : !!chatNotification) && (
                      <div className="absolute -right-1 -top-2 h-[21px] min-w-[21px] rounded-full border-2 border-primary25 bg-primary400 px-1 text-center text-xs text-primary25">
                        {pathname !== "/chat" ? data?.count : chatNotification}
                      </div>
                    )}
                  </Link>
                </div>

                <hr className="h-0 w-6 rotate-90 border-tertiary50 " />
              </>
            )}

            {!isAuthenticated && (
              <Button
                id="btn-handle-signin"
                variant="solid"
                onClick={handleSignin}
              >
                Masuk
              </Button>
            )}

            {session?.user?.username && (
              <Dropdown
                trigger={
                  <div
                    className="ml-auto mt-1 flex h-[58px] w-[150px] cursor-pointer items-center px-2 sm:w-[248px]"
                    data-test="header-userInfoSection"
                  >
                    <div className="mr-2">
                      <Avatar
                        online={false}
                        size="md"
                        src={{ text: session?.user.fullName || "" }}
                      />
                    </div>
                    <div className="mr-4 flex flex-col">
                      <h1
                        data-test="header-user-fullname"
                        className="text-wrap text-caption-sm-semibold min-w-[130px] max-w-[140px] overflow-hidden overflow-ellipsis whitespace-nowrap sm:text-base"
                      >
                        {session?.user?.username || ""}
                      </h1>
                      <p
                        data-test="header-user-role"
                        className="text-[10px] capitalize text-tertiary300 sm:text-xs"
                      >
                        Penyedia
                      </p>
                    </div>
                    <div data-test="header-dropdown-button">
                      <ChevronDown width={18} />
                    </div>
                  </div>
                }
              >
                <div data-test="dropdown-options">
                  <Link
                    href={getAccountPpnUrl(session?.user?.personaId).setting}
                    target="_blank"
                    data-test="header-profile-link"
                  >
                    <DropdownItem
                      classes={{
                        content:
                          "hover:!bg-primary25 flex items-center cursor-pointer gap-2",
                      }}
                    >
                      <Settings width={18} />
                      <p data-test="dropdown-option">Profile</p>
                    </DropdownItem>
                  </Link>
                  <DropdownItem
                    data-test="header-logout-button"
                    onClick={handleSignout}
                    classes={{
                      content:
                        "hover:!bg-primary25 flex items-center cursor-pointer gap-2",
                    }}
                  >
                    <LogOut width={18} />
                    <p data-test="dropdown-option">Keluar</p>
                  </DropdownItem>
                </div>
              </Dropdown>
            )}
          </section>
        )}
      </div>
    </div>
  )
}

export default Header
