"use client"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { SendbirdWrapper } from "chat"
import { usePathname } from "next/navigation"
import type { Session } from "next-auth"
import { useState } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { SnackbarProvider } from "shared-ui"

import FirebaseProvider from "@/components/firebase-provider"
import Header from "@/components/header/Header"
import NotificationProviders from "@/components/notification-provider"
import SessionProvider from "@/components/session-provider"

import ProductListUpdater from "./(sidebar-layout)/products/components/ProductListUpdater"
import { SENBIRD_APP_ID } from "@/config/client"
import OldSnackbarProvider from "@/utils/useSnackbar"

interface IClientProvidersProps {
  children: React.ReactNode
  session: Session | null
}

export default function ClientProviders({
  session,
  children,
}: IClientProvidersProps) {
  const [queryClient] = useState(() => new QueryClient())
  const pathname = usePathname()

  // TODO: need to update the foldering system to exclude header for some pages, like in buyer
  const pagesWithoutHeader = ["/shipping-label-multishipment/courier-provider"]

  return (
    <SessionProvider session={session}>
      <QueryClientProvider client={queryClient}>
        <FirebaseProvider>
          <DndProvider backend={HTML5Backend}>
            <SnackbarProvider>
              <OldSnackbarProvider>
                <NotificationProviders session={session}>
                  <div className="flex h-screen w-full flex-col pb-4">
                    {!!session &&
                      (pathname && pagesWithoutHeader.includes(pathname) ? (
                        <></>
                      ) : pathname !== "/chat" ? (
                        <Header session={session} />
                      ) : (
                        <SendbirdWrapper
                          appId={SENBIRD_APP_ID}
                          userId={session?.user?.sellerId ?? ""}
                          name={session?.user?.name ?? ""}
                        >
                          <Header session={session} />
                        </SendbirdWrapper>
                      ))}
                    <ProductListUpdater />
                    {children}
                  </div>
                </NotificationProviders>
              </OldSnackbarProvider>
            </SnackbarProvider>
          </DndProvider>
        </FirebaseProvider>
      </QueryClientProvider>
    </SessionProvider>
  )
}
