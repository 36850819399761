import { create } from "zustand"
import { createJSONStorage, devtools, persist } from "zustand/middleware"

import { defaultStoredProduct } from "./constants"
import type { TStoredProduct } from "./types"

type TUseProductStoreState = {
  storedProduct: TStoredProduct
  updateStoredProduct: (updatedFields: Partial<TStoredProduct>) => void
  clearStoredProduct: () => void
}

export const useProductStore = create<TUseProductStoreState>()(
  devtools(
    persist(
      (set, get) => ({
        storedProduct: defaultStoredProduct,
        updateStoredProduct: (updatedFields) =>
          set(() => ({
            storedProduct: {
              ...get().storedProduct,
              ...updatedFields,
            },
          })),
        clearStoredProduct: () =>
          set({
            storedProduct: defaultStoredProduct,
          }),
      }),
      {
        name: "product-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
)
